import { Button, Icon, Unifree } from '@yes.technology/react-toolkit'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import useModal from '../../../shared/hooks/useModal'
import WebcamCapture from '../../../shared/WebcamCapture'
import useUploadS3 from '../../hooks/useUploadS3'
import useGetObjectS3 from '../../hooks/useGetObjectS3'
import useDocument from '../../../shared/hooks/useDocument'
import FileUploader from '../../../shared/FileUploader'
import WebcamView from '../../../shared/WebcamView'
import { formatFileSize } from 'modules/shared/utils/formatFileSize'

const ActivityDocumentPhoto = ({ inputValue, onChange, label, required }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { createOrUpdateDocument } = useDocument()
  const { isOpenModal, openModal, closeModal } = useModal()
  const { uploadFile } = useUploadS3()
  const { getObject } = useGetObjectS3()

  const [view, setView] = useState(false)
  const [values, setValues] = useState()

  useEffect(() => {
    if (inputValue !== '') {
      setValues(JSON.parse(inputValue))
    }
  }, [inputValue])

  const onSuccessUploadFile = async (url, filename) => {
    const file = await getObject(filename)
    const values = inputValue && JSON.parse(inputValue)
    const { document, statusDes } = await createOrUpdateDocument({
      uuid: values?.uuid,
      content_type: file.contentType,
      url,
      complement: JSON.stringify({ size: file.contentLength })
    })
    onChange(
      JSON.stringify({
        ...values,
        uuid: document.uuid,
        uuid_status: statusDes,
        des: document.des,
        contentType: file.contentType,
        size: file.contentLength,
        url,
        file: file.image
      })
    )

    dispatch({
      type: 'api/RECEIVED',
      data: ''
    })
  }

  const capturePhoto = (img) => {
    dispatch({
      type: 'api/REQUESTED',
      data: ''
    })

    // close camera
    closeModal()

    uploadFile({
      file: img,
      onSuccess: onSuccessUploadFile,
      fileType: 'base64',
      onError: onErrorUploadFile
    })
  }

  const isNonEmptyString = (value) => value !== undefined && value !== ''

  const onChangeDescription = (value) => {
    onChange(
      JSON.stringify({
        ...values,
        des: value
      })
    )
  }

  const saveDescription = (event) => {
    if (!inputValue || !values?.file) {
      return
    }

    // workaround to solve a react issue when an event triggers a re-render the other events don't fire. As discussed this workaround should be deleted in a future refactor
    setTimeout(async () => {
      const values = JSON.parse(inputValue)
      const { document, statusDes } = await createOrUpdateDocument({
        uuid: values.uuid,
        des: event.target.value
      })

      onChange(
        JSON.stringify({
          ...values,
          uuid: document.uuid,
          des: event.target.value,
          uuid_status: statusDes
        })
      )
    }, 500)
  }

  const onErrorUploadFile = () => {
    dispatch({
      type: 'api/RECEIVED',
      data: ''
    })
  }

  const uploadLocalFile = (file) => {
    dispatch({
      type: 'api/REQUESTED',
      data: ''
    })

    uploadFile({
      file,
      onSuccess: onSuccessUploadFile,
      onError: onErrorUploadFile
    })
  }

  const clearData = () => {
    onChange('')
    setValues('')
  }

  const closeWebcam = () => {
    // close camera
    closeModal()
  }

  return (
    <>
      {!isOpenModal && (
        <div style={{ display: 'grid', rowGap: '8px' }}>
          <Unifree
            label={t('activity-document-photo.labels.des')}
            value={values?.des || ''}
            onChange={onChangeDescription}
            onBlur={saveDescription}
            data-testid='description-field'
            disabled={!values?.file}
            placeholder={t('activity-document-photo.placeholders.des')}
          />
          <div className='d-flex align-items-end'>
            <div className='flex-grow-1'>
              <Unifree
                label={label || t('activity-document-photo.labels.uuid-foto')}
                disabled={!isNonEmptyString(values?.uuid)}
                informational={isNonEmptyString(values?.uuid)}
                value={values?.uuid || ''}
                required={required}
                placeholder={t(
                  'activity-document-photo.placeholders.uuid-foto'
                )}
              />
            </div>
            {values?.file ? (
              <Button
                onClick={() => setView(true)}
                variant='secondary'
                style={{ width: '40px', marginLeft: '4px' }}
              >
                <Icon iconName='Visualization' />
              </Button>
            ) : (
              <Button
                onClick={openModal}
                style={{ width: '40px', marginLeft: '4px' }}
              >
                <Icon iconName='TakePhoto' />
              </Button>
            )}
            {!values?.file && (
              <FileUploader
                onChange={uploadLocalFile}
                accept='image/*'
                variant='secondary'
              />
            )}
            {values?.file && (
              <Button
                variant='secondary'
                onClick={clearData}
                style={{ width: '40px', marginLeft: '4px' }}
              >
                <Icon iconName='Cleaner' />
              </Button>
            )}
          </div>
          <Unifree
            label={t('activity-document-photo.labels.status')}
            disabled={!isNonEmptyString(values?.uuid_status)}
            informational={isNonEmptyString(values?.uuid_status)}
            value={values?.uuid_status || ''}
            required={required}
          />
          <div className='row'>
            <div className='col-6'>
              <Unifree
                label={t('activity-document-photo.labels.content-type')}
                disabled={!isNonEmptyString(values?.contentType)}
                informational={isNonEmptyString(values?.contentType)}
                value={values?.contentType || ''}
                required={required}
                placeholder={t(
                  'activity-document-photo.placeholders.content-type'
                )}
              />
            </div>
            <div className='col-6'>
              <Unifree
                label={t('activity-document-photo.labels.size')}
                disabled={!isNonEmptyString(values?.size)}
                informational={isNonEmptyString(values?.size)}
                value={values?.size ? formatFileSize(Number(values.size)) : ''}
                required={required}
                placeholder={t('activity-document-photo.placeholders.size')}
              />
            </div>
          </div>
          <div className='w-100 d-flex justify-content-center align-items-center'>
            {values?.file ? (
              <img
                src={values.file}
                className='img-fluid'
                alt={`${values?.des || 'Photo'}`}
              />
            ) : (
              <div className='w-100 webcam-no-image rounded'>
                {t('activity-document-photo.no-image')}
              </div>
            )}
          </div>
        </div>
      )}
      {isOpenModal && (
        <WebcamCapture onSuccess={capturePhoto} onCancel={closeWebcam} />
      )}
      {view && (
        <WebcamView onCancel={() => setView(false)} image={values.file} />
      )}
    </>
  )
}

export default ActivityDocumentPhoto
